export const zhHantLanguage = {
  search: "搜索",
  project: "項目",
  collect: "收藏區",
  toolLibrary: "卡片庫",
  materialLibrary: "素材庫",
  background: "背景",
  framework: "框架",
  section: "分區",
  analysis: "學習分析",
  box: "盒子",
  myProject: "我的項目",
  newProject: "新建項目",
  createProject: "創建項目",
  createGroup: "創建分組",
  ungrouped: "未分組",
  toolClass: "控制類",
  start: "主題卡片",
  themeDesc: "請輸入主題",
  stage: "階段卡片",
  stageName: "階段",
  stageDesc: "請輸入階段名稱",
  task: "任務卡片",
  taskName: "任務",
  taskTitle: "任務名稱",
  taskDesc: "請輸入任務名稱",
  taskContent: "任務描述",
  taskContentDesc: "請輸入任務描述",
  resources: "內容類",
  card: "資源卡片",
  select: "選項卡片",
  group: "分組",
  interactive: "互動類",
  thinking: "思維類",
  thinkingCard: "思維卡片",
  evaluate: "評價類",
  ai: "AI類",
  aiCard: "AI卡片",
  fullScreen: "全屏",
  clear: "清空",
  exitFullScreen: "退出全屏",
  run: "生成",
  projection: "演示",
  exitProjection: "退出演示",
  previousPage: "上一頁",
  nextPage: "下一頁",
  logout: "推出小組",
  straightLine: "直線",
  polyline: "折線",
  filletLine: "圓角折線",
  curve: "曲線",
  enableBoxSelection: "啓用框選",
  Uncheck: "取消框選",
  enlarge: "放大",
  reduce: "縮小",
  revoke: "撤銷",
  restore: "恢復",
  minimap: "小地圖",
  import: "導入",
  export: "導出",
  pictureFiles: "图片文件",
  jsonFiles: "Json文件",
  close: "關 閉",
  confirm: "確 定",
  tip: "請選擇導出其中一種文件",
  editMode: "編輯模式",
  readOnlyMode: "只讀模式",
  tip1: "此操作將清空畫布中除了起始（主題卡片）外的所有元素, 是否繼續?",
  tip2: "提示",
  cancel: "取 消",
  tip3: "清空成功!",
  tip4: "請從起始點（主題卡片）出發，順序連接所有需要生成的卡片內容",
  tip5: "不存在主題卡片,請加入",
  tip6: "存在選擇題卡片連線未設置路徑,請認真檢查修改後再生成",
  tip7: "請從起始點（主題卡片）出發，順序連接所有需要演示的卡片內容",
  tip8: "存在選擇題卡片連線未設置路徑,請認真檢查修改後再演示",
  tip22: "請選擇起始點（主題卡片）再生成",
  tip125: "請選擇起始點（主題卡片）再演示",
  tip24: "是否在此生成腳手架模板？",
  tip23: "創建成功",
  Backspace: "刪除卡片（Backspace或者Delete）",
  clone: "克隆卡片（Ctrl+C和Ctrl+V或者Cmd+C和Cmd+V）",
  top: "置於頂層（Ctrl+Shift+]或者Cmd+Shift+]）",
  bottom: "置於底層（Ctrl+Shift+[或者Cmd+Shift+[）",
  forward: "上移一層（Ctrl+X或者Cmd+X）",
  backward: "下移一層（Ctrl+B或者Cmd+B）",
  previewMode: "預覽模式",
  tip9: "請先允許該網頁使用麥克風",
  title: "標題",
  titleDesc: "請輸入標題",
  titleColor: "標題顏色",
  describe: "描述",
  describeDesc: "請輸入描述",
  notes: "備註",
  notesDesc: "請輸入備註",
  icon: "圖標",
  upload: "點擊上傳",
  uploadDesc: "只能上傳jpg/png文件",
  addIcon: "搜索圖標",
  transparentIcon: "透明圖標",
  text: "文本",
  textDesc: "請輸入文本",
  iframe: "內置鏈接",
  iframeDesc: "請輸入內置鏈接",
  embedCode: "嵌入代碼",
  embedCodeDesc: "請輸入嵌入代碼",
  picture: "圖片",
  searchImages: "搜索圖片",
  searchImagesDesc: "請輸入內容",
  change: "換一組",
  tip10: "拼命搜索中",
  video: "視頻",
  CenterUpAndDown: "上下居中",
  selfAdaption: "自適應",
  voice: "語音",
  startRecording: "開始錄音",
  endRecording: "結束錄音並上傳",
  RecordingTime: "錄音時長",
  question: "正文",
  option: "選項",
  optionDesc: "請輸入選項內容",
  material: "素材",
  selectTemplate: "選擇模板",
  preset: "預設",
  edit: "編輯",
  enterGroup: "進入分組",
  groupName: "分組名",
  enter: "進入",
  tip11: "分組不存在",
  topic: "主題",
  enterTopic: "請輸入主題",
  gain: "獲取",
  description: "描述",
  tip12: "是否獲取關鍵詞？",
  tip13: "是",
  tip14: "重新獲取關鍵詞",
  tip15: "是否獲取追加問題？",
  tip16: "重新獲取問題",
  keyword: "關鍵詞",
  runResult: "生成結果",
  directory: "目錄",
  nodeFiltering: "節點篩選",
  all: "全部",
  responsibleTasks: "負責任務",
  participatingTasks: "參與任務",
  projectName: "項目名稱",
  student: "學 生",
  collaborate: "協 作",
  tip17: "此功能暫未開通",
  tip18: "此操作將刪除選中的卡片以及關聯的連線, 是否繼續?",
  question_new: "追問",
  tip19: "選擇路徑",
  tip21: "選擇小組",
  replace: "替換背景",
  mindMap: "設定思維導圖",
  promptsList: [
    {
      value: 0,
      label: "智能階段",
      index: 0,
      prompt: [
        {
          value: 0,
          label:
            "請你以段落的形式幫我設計“關於科學探究”的課程階段，不需要包含課程名稱，只需要包含階段名稱，不需要包含任務名稱以及任務描述，例如 第一階段：提出問題 第二階段：背景研究 第三階段：作出假設 第四階段：設計實驗進行探究 第五階段：收集數據歸類整理 第六階段 分析數據形成觀點",
        },
        {
          value: 1,
          label:
            "請你以段落的形式幫我設計“關於英語寫作”的課程階段，不需要包含課程名稱，只需要包含階段名稱，不需要任務名稱以及任務描述，例如 第一階段：頭腦風暴 第二階段：框架編寫 第三階段：表達比較 第四階段：自由創作 第五階段：同伴互評 第六階段：課堂總結",
        },
      ],
    },
    {
      value: 1,
      label: "智能任務",
      index: 1,
      prompt: [
        {
          value: 0,
          label:
            "請你以Markdown的形式幫我設計一個主題為「製作機器人」的課程活動，內容僅包含子任務名稱以及任務描述，每個任務之間需要換行，不需要輸出標題。 例如 任務一：XXX 任務描述：XXX 任務二：XXX 任務描述：XXX",
        },
        {
          value: 1,
          label:
            "請你以Markdown的形式幫我設計小學數學課裏的「等腰三角形」的教學任務，任務內容包含任務名稱以及任務描述，每個任務之間需要換行，不需要輸出標題。例如 任務一：XXX；任務描述：XXX 任務二：XXX 任務描述：XXX",
        },
        {
          value: 0,
          label:
            "請你以Markdown的形式幫我設計通過科學探究的方式開展「研究滑動摩擦的大小跟哪些因素有關」的任務，內容僅包含子任務名稱以及任務描述，每個任務之間需要換行，例如 任務一：提出科學問題 任務描述：「影響物體所受滑動摩擦力大小的因素可能有哪些？」 任務二：建立科學假說 任務描述：「查閱一些相關的書籍或網絡文獻，根據已有的知識來推理，寫下合理的有根據的猜測。」 任務三：設計並開展科學實驗 任務描述：「實驗設計需明確實驗流程及步驟，包含實驗材料、實驗組與對照組、自變量與因變量以及需要記錄的數據。設計完成後請按照計劃開展實驗。」 任務四：收集和分析實驗數據 任務描述：「通過表格的形式整理實驗中記錄的數據。」 任務五：總結科學結論 任務描述：「請仔細分析收集到的數據，必要時通過統計檢驗來推導結論，總結實驗結果。",
        },
        {
          value: 1,
          label:
            "請你以Markdown的形式幫我設計通過歷史學科探究的方式開展「戰後資本主義世界經濟體系的形成」的教學任務，內容僅包含子任務名稱以及任務描述，每個任務之間需要換行，例如 任務一：整體感知 任務描述：「你如何認識戰後資本主義世界體系？自主閱讀，找出與資本主義世界經濟體系相關的內容。」 任務二：基礎探究 任務描述：「找出國際貨幣基金組織和布雷頓森林體系的聯系。」 任務三：深入探究 任務描述：「結合美國在戰後資本主義經濟體系的地位，分析布雷頓森林體系建立的背景。」 任務四：探究總結 任務描述：「將戰後資本主義世界體系的結構繪製為框架圖。」 任務五：拓展延伸 任務描述：「布雷頓森林體系的解體標誌美元獲得了黃金的生命，你認為這是否是美國衰落的象征，請從辯證的角度回答問題。」",
        },
        {
          value: 2,
          label:
            "請你以Markdown的形式幫我設計通過看圖作文教學的方式開展「燕子安家看圖作文」的任務，內容僅包含子任務名稱以及任務描述，每個任務之間需要換行，例如 任務一：觀察圖片 任務描述：「仔細看圖，理解圖意，圖片描述了一個什麽樣的故事？請大家註意觀察圖中的細小部分。」 任務二：發揮想象 任務描述：「展開合理想象，你覺得它們會說什麽？會做什麽？它們為什麽會這樣說，這樣做？」 任務三：口述訓練 任務描述：「選擇合適的語氣，說一說你看到的內容、它們之間的關系以及你的想象。」 任務四：組織成文 任務描述：「在語言描述的基礎上，增加細節說明，以及你的思考，用文字編寫完成一個完整的故事。註意文章需分段，並使用正確的標點。」",
        },
        {
          value: 3,
          label:
            "請你以Markdown的形式幫我設計通過六頂帽子的方法來開展「如何解決校門口交通擁堵問題」的任務，內容僅包含子任務名稱以及任務描述，每個任務之間需要換行，例如 任務一：陳述問題 任務描述：「要想解決校門口的交通擁堵問題，你需要關於現狀的什麽信息？」 任務二：提出解決問題的方案 任務描述：「要想解決這個問題，你有什麽想法和建議？你認為可能的解決辦法和行動過程是什麽？」 任務三：評估方案優點 任務描述：「你為什麽認為這個方案有效？你認為它為什麽值得做？」 任務四：列舉方案缺點 任務描述：「你認為這個方案的缺點是什麽？實施會遇到什麽問題？」 任務五：進行方案的直覺判斷 任務描述：「從直覺來考慮，你對於這個方案的評價如何？」 任務六：總結陳述與作出決策 任務描述：「1. 現在對於這個問題的解決，我們目前的討論進度是什麽？2. 如果要繼續下一步推進，我們還需要做什麽？」",
        },
        {
          value: 4,
          label:
            "請你以Markdown的形式幫我設計出通過專家拼圖法來開展「整理各朝代繪畫特點」的任務，內容僅包含子任務名稱以及任務描述，每個任務之間需要換行，例如 任務一：學生分組，劃分拼圖 任務描述：全班分為若幹小組，各小組成員分別認領總任務中的各個子任務/子概念 任務二：領取拼圖，自主探究 任務描述：小組成員單獨思考並進行針對子任務/子概念的探究 任務三：統一拼圖，交流分享 任務描述：領取相同子任務/子概念的同學組建成為專家小組，進行交流分享 任務四：合作交流，組建拼圖 任務描述：每位小專家回到本組內進行交流，每位組員都需要講解自己負責的拼圖板塊。小組成員整體進行交流與討論後，形成指向總任務的解決方案，完成拼圖。",
        },
        {
          value: 5,
          label:
            "請你以Markdown的形式幫我設計通過蘇格拉底提問的方法來開展「什麽時候你能感受到友誼」的任務，內容僅包含子任務名稱以及任務描述，每個任務之間需要換行，例如 任務一：這是什麽？ 任務描述：你說的「友誼」具體是指什麽？可不可以舉個例子，說明你想要表達的概念？ 任務二：為什麽？ 任務描述：你為什麽會這麽想？為什麽會做這樣的設想？你為什麽相信這是真的？ 任務三：所以呢？ 任務描述：所以這意味著什麽呢？所以你如何證明或推翻這個假設？ 任務四：還有別的可能嗎？ 任務描述：別的不同觀點可能會是什麽？可以用其他的方式或方法來對待這件事嗎？還可以有其他的可能嗎？",
        },
      ],
    },
    {
      value: 2,
      label: "智能出題",
      index: 2,
      prompt: [
        {
          value: 0,
          label:
            "請你以富文本的形式給我出四個關於清朝歷史的選擇題，每個選擇題選項不少於4個，需要答案，但不需要解釋，符號請使用中文的，語言使用繁體",
        },
        {
          value: 1,
          label:
            "請你以富文本的形式給我出三個關於小學數學單位換算的選擇題，每個選擇題選項不少於4個，需要答案，但不需要解釋，符號請使用中文的，語言使用繁體",
        },
      ],
    },
    {
      value: 3,
      label: "智能問答",
      index: 3,
      prompt: [
        {
          value: 0,
          label:
            "請你以Markdown的形式給我出一道關於清朝歷史的問答題，符號請使用中文的，例如 問：清朝的建立時間是哪一年？由哪個族群建立？建立者的姓氏是什麽？ 答：清朝建立於1644年，由滿族建立，建立者的姓氏是努爾哈赤。",
        },
      ],
    },
    {
      value: 12,
      label: "跨學科學習",
      index: 12,
      prompt: [
        {
          value: 0,
          label:
            "我需要以「設計3天夏令營的健康飲食食譜」為主題，設計一個跨學科融合的課程。我需要你完成以下事情： 1、介紹該課程的基本信息 2、列出4個跨學科概念 3、逐一分解跨學科概念，將其分解為一系列學科概念。4、根據列出的概念群，列出一系列學生可以探究的問題",
        },
        {
          value: 1,
          label:
            "學科核心概念是在學科中，被認為具有最基本和最重要的概念，我們可以通過識別文件中的高頻概念性名詞來找到潛在的學科核心概念。現在，根據該文檔中各個學段目標 ，總結出該學科的核心概念",
        },
        {
          value: 2,
          label:
            "學科核心概念是在學科中，被認為具有最基本和最重要的概念，核心概念應該在多個年級或發展階段中具有持久的重要性，並且能夠為學生提供理解更復雜或更高級概念的基礎。因此，我們可以通過識別文件中的高頻概念性名詞 來找到潛在的學科核心概念。現在，根據該文檔中各個 學段目標 ，總結出該學科的核心概念",
        },
      ],
    },
    {
      value: 4,
      label: "項目式學習",
      index: 4,
      prompt: [
        {
          value: 0,
          label:
            "請給我一些學生開展項目式學習可以使用的主題或問題參考，請說出學生將要解決的問題，以及學生要經歷怎樣的學習活動。",
        },
        {
          value: 1,
          label:
            "請將一個關於生態保護項目的項目式學習展開描述，你需要描述學生如何解決這個問題，你需要至少寫出四個活動，這些活動需要按照前後邏輯關系排列。",
        },
        {
          value: 2,
          label:
            "請對驅動問題為「如何為學校建造一個富有特色的花壇？」的項目式學習進行子問題拆解，至少拆解為5個子問題，並根據子問題對應寫出各環節的主要活動。",
        },
      ],
    },
    {
      value: 5,
      label: "教學評價",
      index: 5,
      prompt: [
        {
          value: 0,
          label:
            "如果需要給學生的社區服務進行評價，給出評價維度和至少3個等級的表現描述。",
        },
        {
          value: 1,
          label:
            "為6年級學生設計一份關於梧桐山研究報告的評估任務表，並給出參考的報告流程，至少包含8個步驟，並包括地圖、圖片和至少300個詞。",
        },
        {
          value: 2,
          label:
            "創建一個給5年級學生使用的課堂小測試，包含5道多選題，評價學生對於太陽能這個概念的理解。你需要給出題目和正確答案。",
        },
      ],
    },
    {
      value: 6,
      label: "教學設計",
      index: 6,
      prompt: [
        {
          value: 0,
          label:
            "如果需要5年級學生感受「移步換景」的景觀寫作手法，你有什麽合適的閱讀材料推薦？你需要給出材料名稱，以及材料的哪部分內容。",
        },
        {
          value: 1,
          label:
            "設計一個針對8年級學生且關於人類遷徙主題的地理課，並在課程中設計至少1項小組活動。",
        },
        {
          value: 2,
          label:
            "設計一個針對5年級學生的課程，課程綜合科學和信息技術領域，解決生物與環境領域的生活問題，你需要給出完整的課程框架和活動。",
        },
        {
          value: 3,
          label:
            "如果3年級的學生不能理解光合作用的實現過程，需要你幫我設計一個支持他們理解的教學活動，需要包含活動的形式、實施材料和清單。",
        },
        {
          value: 4,
          label: "請基於貝葉斯定理為8年級學生出三道題目。",
        },
        {
          value: 5,
          label:
            "如果需要八年級學生了解尼羅河流域的文化發展史，你有哪些推薦的網站或參考書籍？",
        },
      ],
    },
    {
      value: 7,
      label: "班級管理",
      index: 7,
      prompt: [
        {
          value: 0,
          label:
            "創建一組給一年級學生使用的班級口號，要求大家註意衛生、保護環境，口號需要對仗工整，符合一年級學生的理解水平。",
        },
        {
          value: 1,
          label:
            "設計一套用於6年級學生的班級管理規章製度，內容需要包括學習、紀律、衛生、思想品德方面。",
        },
      ],
    },
    {
      value: 8,
      label: "課堂組織",
      index: 8,
      prompt: [
        {
          value: 0,
          label:
            "請為「製作垃圾分類宣傳單」的小組活動設計小組分工表，每個小組的成員為4-6人。",
        },
        {
          value: 1,
          label:
            "請給5年級「校園植物圖鑒」社團課程設計一份小組合作公約，需包含小組成員信息、小組項目目標、填寫日期，總長度不超過300字，並且提供至少3處學生自行填寫的部分。",
        },
        {
          value: 2,
          label:
            "請用蘇格拉底提問的方式，引導5年級學生拆解驅動問題：如何解決教室黑板反光的問題？其中需包含對於反光原因的分析與實驗探究。",
        },
      ],
    },
    {
      value: 9,
      label: "教師發展",
      index: 9,
      prompt: [
        {
          value: 0,
          label:
            "教師需要理解項目式學習的理論基礎和基礎概念，你需要生成一份閱讀清單，要求內容為中文書籍或文獻。",
        },
        {
          value: 1,
          label:
            "設計一個教師進行個人學期總結的框架，需要體現在教學、教研、個人學習方面的進步。",
        },
      ],
    },
    {
      value: 10,
      label: "專案式學習",
      index: 10,
      prompt: [
        {
          value: 0,
          label:
            "我希望你擔任課程設計師。我將提供有關專案式學習的課程設計的一些要求，這些要求可能包括年級、學科、課時、內容等，而你的工作是給出符合要求的課程內容。這可能涉及分析專案式學習的主題、問題，將內容匹配到年級與學科，並且設計活動。我的第一個要求是“我需要一些和語文學科相關的專案式學習主題”。",
        },
        {
          value: 1,
          label:
            "如果圍繞你給出的第一個主題“創作故事”開展專案式學習，請設計一個針對小學五年級學生的驅動性問題。",
        },
        {
          value: 2,
          label:
            "請將一個關於生態保護的專案式學習展開描述，你需要描述學生如何解決這個問題，你需要至少寫出四個活動，這些活動需要按照前後邏輯關係排列。",
        },
        {
          value: 3,
          label:
            "請對驅動問題為“如何為學校建造一個富有特色的花壇？”的專案式學習進行活動設計，至少需要將問題拆解為5個活動，並按照實施順序給出各活動的主要任務。",
        },
        {
          value: 4,
          label:
            "根據你給出的針對驅動問題為“如何創作一個引人入勝的故事，並且讓讀者產生共鳴？”的專案式學習活動設計，設計出匹配小學五年級語文學科的學習目標，你需要將學習目標分為3類，分別是學生將知道、學生將理解和學生將能夠。請以序號加目標內容的方式給出學習目標。",
        },
        {
          value: 5,
          label:
            "你需要給出一個最終任務，學生需要完成這個任務來體現出自己對於“如何創作一個引人入勝的故事，並且讓讀者產生共鳴？”這個專案式學習課程的教學目標的達成，這個最終的任務是什麼？",
        },
        {
          value: 6,
          label:
            "我需要知道怎麼評價學生這個最終任務的完成情況，我需要知道評價的維度，以及每個維度的表現描述，最好以表格的形式輸出你設計的評價量表。",
        },
      ],
    },
    {
      value: 11,
      label: "代碼分析",
      index: 11,
      prompt: [
        {
          value: 0,
          label: "這段代碼實現了什麼效果？",
        },
        {
          value: 1,
          label: "請描述這段代碼。",
        },
        {
          value: 2,
          label: "根據這段代碼，給我一些修改意見。",
        },
      ],
    },
  ],
  my: "我的",
  collect1: "收藏1",
  collect2: "收藏2",
  share: "共享",
  share1: "共享1",
  share2: "共享2",
  share4: "別人共享",
  scaffold: "腳手架",
  resou: "資源",
  AI: "AI",
  scientific_inquiry: "科學探究",
  Turmin: "圖爾敏法",
  SE: "5E教學設計",
  STEM: "STEM",
  SSE: "3SE課程設計模型",
  SEX: "5EX模型",
  generate: "生成",
  generate2: "獲取任務",
  generate3: "獲取資源",
  prompts: "請輸入prompts",
  hide: "隱藏標題",
  ai_analyze: "AI分析中...",
  stageTip: "階段:",
  taskTip: "任務:",
  chatroom_message_open: "接收通知",
  chatroom_message_close: "通知:",
  taskCourseTip: "正在創建任務式課程，請稍候...",
  stageCourseTip: "正在創建階段式課程，請稍候...",
  stageProjectTip: "正在創建項目，請稍候...",
  networkTip: "網絡已連接，正在重新獲取數據，請稍候...",
  notNetworkTip:
    "網路連線異常。你可以在離線狀態下繼續編輯，內容將在連線網路後自動同步。",
  whiteboard_card: "白板卡片",
  file: "文檔",
  uploadTip: "只支持上傳pdf, doc, docx, ppt, pptx, xls, xlsx文檔",
  taskTip2: "任務",
  tip20:
    "生成的格式不正確，請保證內容中有『任務X：』和『任務描述：』的字樣，如果沒有，請修改或者再次獲取生成！",
  ArProse: "論辯文寫作",
  createErrorTip1: "存在多個主題卡片，請選擇其中一個來創建課程",
  createErrorTip2: "存在歧義，請修改相關連線後再創建課程",
  createCourse: "創建課程",
  runTip2: "存在歧義，請修改相關連線後再生成",
  map_card: "地圖卡片",
  pre_stage: "上一階段",
  next_page: "下一階段",
  export_to: "導出為TXT",
  chat_room: "聊天室",
  my_group: "我的小組",
  close_chat: "關閉聊天",
  open_Collapse: "打開/收起",
  clear_screen: "清屏",
  pbl: "PBL",
  subject: "學科類",
  map_mind: "思維导图",
  distribute_cards: "分發卡片（Ctrl+Q或者Cmd+Q）",
  createErrorTip3: "無法創建非任務以及非階段課程",
  createErrorTip4: "存在多個主題卡片，請選擇其中一個來創建項目",
  createErrorTip5: "存在歧義，請修改相關連線後再創建項目",
  createErrorTip6: "無法創建非階段項目",
  delete: "刪除（Backspace或者Delete）",
  send: "分發（Ctrl+Q或者Cmd+Q）",
  analyseTip: "正在獲取數據進行分析，請稍候...",
  whole: "整體",
  student_new: "個人",
  collaborate_new: "小組",
  achievement: "成果",
  timeMachine: "時光機",
  personalImage: "個人畫像",
  personalProfile: "行為概況",
  resourceContribution: "資源貢獻",
  personnelRelations: "協作關係",
  heatRelations: "活度熱力圖",
  contentTip: "內容即將推出，敬請期待......",
  selectTip: "請選擇",
  setMap: "設置地圖",
  studentTip1: "創建卡片",
  studentTip2: "資源分享",
  studentTip3: "編輯",
  studentTip4: "發言",
  studentTip5: "創建模版",
  studentTip6: "連線",
  studentTip7: "編輯其他人卡片的次數",
  times: "次數",
  studentTip8: "生成資源的次數",
  camera_card: "攝像頭",
  groupShare: "貢獻度",
  confirmSend: "確認分發",
  sendSuccess: "分發成功",
  notSend: "請先選擇小組",
  beautifyAll: "一鍵美化",
  beautifyAllNew: "一鍵美化（Ctrl+M）",
  homeworkCard: "作業卡片",
  homeworkName: "作業",
  homeworkDesc: "請輸入作業題目",
  homeworkTitle: "作業題目",
  homeworkContent: "作業描述",
  pbl1: "PBL觀察筆記",
  pbl2: "PBL介紹",
  pbl3: "PBL跨學科模板",
  pbl4: "團隊成員介紹模板",
  pbl5: "POV表格",
  pbl6: "康奈爾筆記法",
  pbl7: "蘇格拉底式追問",
  six_thinking: "六頂思考帽",
  English_Creative_Writing: "英語創意寫作",
  dashboard: "儀表盤",
  dashboardTip1: "總活躍度",
  dashboardTip2: "小組參與度",
  dashboardTip3: "卡片數量",
  dashboardTip4: "累計卡片數量",
  dashboardTip5: "減少量",
  dashboardTip6: "增加量",
  create: "創建",
  prd: "項目成果分布",
  lfc: "登錄頻次圖",
  top6: "貢獻度排行榜",
  result_map: "項目成果地圖",
  immersive: "沉浸式",
  cards: "卡片式",
  stageTitle: "階段名稱",
  stageContent: "階段描述",
  stageContentDesc: "請輸入階段描述",
  restore_background: "恢復默認背景",
  select_group_value: "請選擇小組",
  group_achievement: "小組成果排行榜",
  global_relationship_echarts: "全局關係圖",
  select_personage_value: "請篩選",
  Project_outcome: "項目成果圖",
  setCamera: "點擊確定拍照",
  resourceClass: "資源類別圖",
  image: "圖片",
  videoAndAudio: "音視頻",
  content: "文本",
  fileContent: "文檔",
  urlAndIframe: "鏈接",
  icfc: "個人創建頻次圖",
  refreshFrequency: "刷新頻率",
  ofc: "操作頻次圖",
  refresh: "刷新",
  card2: "卡片",
  loading: "加載中......",
  Loaded: "已加載：",
  save: "確定",
  tip25: "拼命加載中",
  vectorgraph: "矢量圖",
  satellite_image: "衞星圖",
  store: "保存",
  tip26: "請輸入地址",
  tip27: "創作",
  tip28: "樣例",
  download: "下載",
  tip29: "新增了",
  tip30: "張卡片",
  tip31: "刪除了",
  tip32: "上線了",
  tip33: "下線了",
  tip34: "執行了清空操作！",
  edit_Card: "編輯卡片",
  call_word: "提示詞",
  group_list: "小組列表",
  global_view: "全局查看",
  look_last_card: "查看上一次編輯的卡片",
  view_topic: "查看初始主題",
  tip35: "單擊左鍵選擇",
  tip36: "想要的腳手架資源卡片後，該",
  tip37: "資源模版可跟隨鼠標單擊放置",
  tip38: "在對應的位置哦！",
  tip39: "單擊選擇後，鼠標跟隨一個模",
  tip40: "版縮略圖，單擊放置在畫布對",
  tip41: "應位置",
  errorTip: "數據出現異常，請使用異常修復按鈕進行修復",
  repair: "異常修復",
  errorTip1: "此修復操作將刪除所有的連線, 是否繼續?",
  tip42: "修復成功",
  save_map: "地圖保存中...",
  unable_to_connect_with_theme: "不支持連接主題卡片",
  tip43: "不支持卡片連接自身",
  Sun: "周日",
  Mon: "周一",
  Tues: "周二",
  Wed: "周三",
  Thur: "周四",
  Fir: "周五",
  Sat: "周六",
  deleteGraps: "刪除",
  aidh: "(對話)：",
  cardfx: "(卡片分析)：",
  userxwfx: "(用戶行為分析)：",
  contribution_degree: "該成員貢獻度表現為：",
  contribution: "貢獻占比為：",
  ap: "該成員活躍度表現為：",
  dynamic1: "非常活躍",
  dynamic2: "較活躍",
  dynamic3: "活躍",
  dynamic4: "一般",
  dynamic5: "不活躍",
  zhang: "張",
  add_node: "新增卡片：",
  delete_card: "刪除卡片：",
  article: "條",
  add_edge: "新增連線：",
  updata_edge: "操作連線：",
  delete_edge: "刪除連線：",
  operation_record: "操作記錄",
  sumbit_job: "提交作業",
  requirements: "要求",
  job_reception_area: "作業接收",
  jobTip: "正在提交作業，請稍候...",
  tip44: "提交成功",
  tip45: "自適應",
  CPOTE: "C-POTE模式案例",
  CPOTE2: "C-POTE模式課程設計流程",
  subject_icon: "各科義務教育課標",
  educational_base: "未來教育基地第二期匯總",
  Project_learning_curriculum_design_framework: "項目式學習課程設計框架",
  lxdh: "連續對話:",
  unable_to_connect_with_other: "此兩卡片已經連接，不支持重新再連",
  kwl: "KWL圖",
  w: "W圖",
  x: "X圖",
  y: "Y圖",
  we: "韋恩三圓圈圖",
  venn: "韋恩圖",
  fishbone_diagram: "魚骨圖",
  launch: "展開",
  closeItem: "收起",
  tip46: "請給“",
  tip47: "”打標籤",
  tip48: "發起人或貢獻者",
  title48: "對於某個主題或問題，給予新的意義或新的界定，或提出解決的辦法。",
  tip49: "信息探求者",
  title49: "強調通過從其他人那裏獲取信息的背景,得到有關事實",
  tip50: "意見探索者",
  title50: "獲取質量更高的數據類型,如態度、評價和情緒等",
  tip51: "信息提供者",
  title51: "提供經驗或權威報道。",
  tip52: "意見提供者",
  title52: "對建議提出看法、意見、增補、修正、支持或反對。",
  tip53: "推敲者",
  title53: "舉説明或提供合理的解釋，或將意見推演。",
  tip54: "協調者",
  title54:
    "為各種意見搭起橋樑，並在各個團體間穿梭，嘗試將意見與行為結合在一起。",
  tip55: "導引者",
  title55: "指出意見的意向，導引目標或提供討論方向。",
  tip56: "批判者",
  title56: "提出一些標準來評估、諮詢團體講座的實施、邏輯或程序問題。",
  tip57: "加油者",
  title57: "鼓勵團體決策或採取行動或刺激團體達到高潮。",
  tip58: "行動專家",
  title58: "促成團體行動、分配任務、管制進度與安排事務。",
  tip59: "記錄者",
  title59: "草擬與記錄提議，登載結論。",
  tip60: "保存標籤",
  tip61: "雙擊卡片啓用攝像頭",
  tip62: "修改了1",
  tip63: "內容為：",
  tip64: "的",
  tip65: "標題為：",
  tip66: "修改的內容為：",
  tip67: "修改的標題為：",
  tip68: "新增了連線",
  tip69: "刪除了連線",
  tip70: "新增了1",
  tip71: "刪除了1",
  tip72: "修改了連線",
  tip73: "移動了1",
  tip74: "",
  tip75: "全選",
  Editing: "編輯模式",
  myGroup: "我的小組",
  among: "其中，",
  add_ap1: "非常大",
  add_ap2: "较大",
  add_ap3: "大",
  add_ap4: "一般",
  add_ap5: "小",
  operation_card: "操作卡片：",
  character: "角色標籤",
  tip76: "快來創作你的白板",
  tip77: "開啓麥克風",
  tip78: "關閉麥克風",
  ask_question: "提問",
  answer_question: "回答",
  sumbit_question: "提交",
  sumbit_question_again: "繼續提問",
  tip79: "按 “/” 顯示提示詞",
  tip80: "文件選擇",
  tip81: "生成文字",
  tip82: "生成圖片",
  tip83: "生成音頻",
  tip84: "正在獲取回答，請稍後",
  tip85: "正在解析語音，請稍後",
  tip86: "圖片大小不能超過4MB",
  tip87: "問題不能為空",
  aite_ai_desc: "請輸入內容，輸入@選擇AI能喚起AI功能",
  tip88: "終止",
  share3: "分享",
  fzcg: "分享鏈接已生成！已將鏈接複製到剪切板中",
  fzsb: "複製失敗",
  tip89: "進入演示模式？",
  tip90: "進入",
  tip91: "複製成功",
  shareContent: "分享內容",
  tip92: "僅演示",
  tip93: "鏈接",
  tip94: "複製鏈接",
  tip95: "邀請碼",
  tip96: "複製邀請碼",
  tip97: "二維碼",
  tip98: "下載二維碼",
  tip99: "是否需要邀請碼",
  tip100: "需要",
  tip101: "不需要",
  tip102: "重新生成",
  tip103: "邀請碼不對，請詢問獲取正確的邀請碼",
  tip104: "選擇小組以及文件格式",
  tip105: "text文件",
  tip106: "文件格式",
  pocket_monster: "口袋妖怪",
  pocket_monster_1: "妖怪1",
  pocket_monster_2: "妖怪2",
  move_key: "移動按鍵",
  move_key_1: "向上",
  move_key_2: "向下",
  move_key_3: "向左",
  move_key_4: "向右",
  move_distance: "移動步長",
  move_distance_x: "x軸",
  move_distance_y: "y軸",
  tip107: "背景顏色",
  tip108: "背景漸變色",
  tip109: "文本顏色",
  tip110: "填充樣式",
  tip111: "獲取不到項目cid，請使用刷新按鈕刷新頁面再重新點擊分享",
  tip112: "創建AI NPC",
  tip113: "修改AI NPC",
  prologue: "開場白",
  isPublish: "發佈權限",
  knowledge_base: "專屬知識庫",
  upload_file: "上傳文件",
  upload_file_desc: "上傳文件的説明",
  tip114: "保存成功",
  upload_image: "上傳圖片",
  select_local_file: "選擇本地文件",
  select_network_file: "選擇網絡文件",
  sumbit_error_message: "請確保描述、prompt、開場白、名稱不為空",
  people: "人物",
  sendButton: "發送",
  tip115: "內容輸入中",
  tip116: "按下Enter鍵，開始對話",
  tip117: "我",
  agent: "(AI智能體)",
  tip118: "：",
  tip119: "白板編輯",
  tip120: "設置背景",
  tip121: "是否隱藏",
  tip122: "不隱藏",
  tip123: "隱藏",
  tip124: "按下Enter鍵，打開",
  geneTask: "生成任務大綱",
  geneTasktext: "正在生成任務大綱，請稍後...",
  geneResourse: "生成教案",
  geneResoursetext: "正在生成教案，請稍後...",
  draw: "畫筆",
  comment: "評論",
  zan: "贊",
  publicGroup: "公共小組",
  role: "權限",
  allStudentRead: "允許學生查看其他小組（除我的小組外）",
  allStudentEdit: "允許學生編輯其他小組（除我的小組外）",
  setRole: "權限設置",
  setRoleSuccess: "權限設置成功",
};
